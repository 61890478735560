<template>
  <div id="app">
    <HomeIndex/>
  </div>
</template>

<script>
import HomeIndex from './components/index.vue'

export default {
  name: 'App',
  components: {
    HomeIndex
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  padding: 0px;
  height: 100%;
}
body{
  margin: 0px;
  height: 100%;
}
html{
  height: 100%;
}
</style>
