<template>
  <div class="content">
    <div style="width: 100%;position: absolute;top: 0px;">
      <div class="logo">星空狐</div>
      <div class="login">
        <el-button size="medium" round>登录</el-button>
      </div>
    </div>
    <div style="background-color: #5db5c5; height: 40%;"></div>
    <div class="title">
      <div style="width: 30%;"><el-avatar style="margin-top: 12px;" :size="120" :src="url3"></el-avatar> </div>
      <div  style="width: 70%;text-align: left;">
        <div style="margin-top: 8px;">星空狐</div>
        <div style="margin-top: 8px;">邮箱：381805218@qq.com</div>
        <div style="margin-top: 8px;">技能：
          <el-tag type="success">Java</el-tag>
          <el-tag type="success">html</el-tag>
          <el-tag type="success">css</el-tag>
          <el-tag type="success">javascript</el-tag>
        </div>
        <div style="margin-top: 8px;">框架：
          <el-tag type="success">spring boot</el-tag>
          <el-tag type="success">supermap webgl</el-tag>
          <el-tag type="success">vue</el-tag>
        </div>
      </div>
    </div>
    <div style=" width: 730px;margin-left: calc((100% - 730px)/2);">
      <div class="header">
        <label>最新文章</label>
      </div>
      <div>
        <div style="display: flex;height: 60px;justify-content: space-between;margin-top: 8px;">
          <div style="display: flex;">
            <el-avatar style="margin-top: 12px;" size="medium" :src="url3"></el-avatar> 
            <div style="line-height: 60px;margin-left: 8px;">星空狐</div>
          </div>
          <div  style="line-height: 60px;"><label>今天</label></div>
        </div>
        <div style="margin-top: 8px;">
          <el-image :src="url2"></el-image>
        </div>
        <div style="text-align: left;font-size: 25px;margin-top: 8px;">
          <label>作者懒还没开始</label>
        </div>
        <div style="text-align: left;margin-top: 8px;">
          <label>作者懒还没开始文章记录,作者懒还没开始文章记录,作者懒还没开始文章记录,作者懒还没开始文章记录,作者懒还没开始文章记录...</label>
        </div>
      </div>
      <!-- <div>
        <div style="display: flex;height: 60px;justify-content: space-between;margin-top: 8px;">
          <div style="display: flex;">
            <el-avatar style="margin-top: 12px;" size="medium" :src="url3"></el-avatar>  
            <div style="line-height: 60px;margin-left: 8px;">星空狐</div>
          </div>
          <div  style="line-height: 60px;"><label>今天</label></div>
        </div>
        <div style="margin-top: 8px;">
          <el-image :src="url2"></el-image>
        </div>
        <div style="text-align: left;font-size: 25px;margin-top: 8px;">
          <label>作者几个有助于用户订阅的建议</label>
        </div>
        <div style="text-align: left;margin-top: 8px;">
          <label>专栏本身的设置： 1、设置一个识别度高的的专栏头像; 2、个人页面的签名需要填写哦，需要强调自己的专业度（; 3、专栏介绍最好详细一些哈，里面也可以包含...</label>
        </div>
      </div> -->
    </div>
    <div style="margin-top: 20px;padding-bottom: 20px;position: fixed;bottom: 0px;width: 100%;">Copyright © 2013-2022 LJY personally owned. All Rights Reserved   <a href="https://beian.miit.gov.cn/" target="_blank">桂ICP备2022009524号-1</a></div>
  </div>
</template>

<script>
export default {
  name: 'HomeIndex',
  props: {
    msg: String
  },
  data(){
    return {
      activeIndex:'1',
      url1:require('../assets/fox.png'),
      url2:require('../assets/2.png'),
      url3:'https://images.xiaozhuanlan.com/photo/2018/72982ca03c4505af0232a0cfbd12e6bc.png!small'
    }
  },
  methods:{
    handleSelect(key,keyPath){
      console.log(key,keyPath);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 /* .el-menu--horizontal>.el-menu-item{
  float: none !important;
  display: inline-block !important;
 } */
 .content{
  width: 100%;
  height: 100%;
 }
 .login button{
    float: right;
    margin-right: 200px;
    margin-top: 8px;
 }

 .title{
    height: 30%;
    width: 730px;
    position: absolute;
    background: white;
    top: 10%;
    border-radius: 5px 5px 0 0;
    left: calc((100% - 730px)/2);
    display: flex;
    flex-direction: row;
    align-items: center;
 }
 .logo{
    float: left;
    margin-top: 8px;
    margin-left: 100px;
    color: white;
    font-size: 30px;
    font-family: fangsong;
 }

 .header{
    border-bottom: 1px solid rgb(204, 204, 204);
    display: block;
    height: 60px;
    text-align: left;
    line-height: 60px;
 }
 .el-tag{
  margin-right: 8px;
 }
</style>
